.logo {
  font-family: "Droid Serif", serif;
  font-weight: bold;
  font-size: 32px;
  color: #5ca1a3;
}

.textColorFooter{
  background-color: #F6F4E8;

}

.routes-wrapper {
  padding-top: 80px;  
  background-color: #F6F4E8;

}

@media (width > 200px) {
  .logo {
    mix-blend-mode: difference;
  }
}

body{
  background-color: #F6F4E8;
}


.navbar {
  transition: height 0.3s ease;
  font-size: 18px;
  color: #9A5345;
  transition-timing-function: ease-in;
  transition: 0.5s;
}

.navbar-right:hover {
  opacity: 0.5;
}












